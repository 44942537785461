import * as React from "react"
import { Link } from "gatsby"
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CoachCard from '../components/CoachCard';
import AvatarMenu from '../components/AvatarMenu';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = ({data}) => {
  const theme = useTheme();

  return (
  <Layout>
    <SEO title="Covid19" />
    <Grid container direction="column" alignItems="center" style={{textAlign: 'center', height: '60vh'}} justify="space-between" spacing={12}>
      <Grid item style={{padding: '2rem', paddingBottom: '1rem'}}>
        <Typography variant="h3" style={{color: '#243E83'}}>Your Safety Is Our Top Priority</Typography>
      </Grid>
      <Grid item sm={8} style={{padding: '2rem'}}>
        <Typography variant="paragraph">
          Our team is committed to facilitating the best possible clinics and practices while maintaining the highest standard
          of health safety possible. As such we are closely adhering to CDC recommendations as well as the <a href="http://www.chrvajuniors.org/chrva/docs/4-21%20UPDATE%20to%202020-2021%20CHRVA%20Participation%20Position%20Statement.pdf?fbclid=IwAR1AAzNYOZ9J4PhKLA6Lj-HxIrFQPnSCjg6VfrJvYRXzByYWp3hBhe15F5k" target="_blank">CHRVA</a> compliance
          guidelines for our events. More info is available in our <Link to="/faq">Frequently Asked Questions</Link>.
        </Typography>
      </Grid>
      <Grid item syle={{padding: '2rem'}}>
        <Typography variant="h6" style={{color: '#243E83', borderBottom: '1px solid #F78D1F', marginBottom: '15px'}}><Link to="/clinics">Stay safe and we hope to see you on the court</Link></Typography>
      </Grid>
    </Grid>
  </Layout>
)}

export default AboutPage